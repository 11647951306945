<template>
    <div>
        
    </div>
</template>
<script>

export default {
    props: {
        show: {
            type: Boolean,
            default: false
        },
    }
}
</script>